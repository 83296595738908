import {useForegroundColor} from 'UI/components/action/buttons/hooks/useForegroundColor';
import {
    getButtonTextSize,
    getIcon,
    getIconPosition,
    getIconSize
} from 'UI/components/action/buttons/utils/utils';

import type {ButtonIcons} from 'Images/icons';

/**
 * Returns the necessary properties and values for rendering a button component.
 * The button can be styled with either a 'dark' or 'light' theme,
 * and its size can be set to 'large' or 'small'.
 * The button can be positioned to the 'left' or 'right' side of the parent container.
 * Optionally, an icon can be passed to the button using the 'icon' parameter.
 *
 * @param style    The style of the button. Can be either 'dark' or 'light'.
 * @param size     The size of the button. Can be either 'large' or 'small'.
 * @param position The position of the button. Can be either 'left' or 'right'.
 * @param icon     (Optional) The icon to be displayed on the button.
 * @returns An object containing the following properties:
 * - foregroundColor: The color to be used for the button foreground.
 * - Icon: The component representing the button icon.
 * - iconPosition: The position of the button icon.
 * - iconSize: The size of the button icon.
 * - textSize: The size of the button text.
 *
 * @example
 * ```ts
 * const buttonProps = useButton('dark', 'large', 'left', 'search');
 * ```
 */
export const useButton = (
    style: 'dark' | 'light',
    size: 'large' | 'small',
    position: 'left' | 'right',
    icon?: ButtonIcons
) => {
    const foregroundColor = useForegroundColor(style);
    const Icon = getIcon(icon);
    const iconSize = getIconSize(size);
    const iconPosition = getIconPosition(position);
    const textSize = getButtonTextSize(size);

    return {
        foregroundColor,
        Icon,
        iconPosition,
        iconSize,
        textSize
    };
};