/* eslint-disable max-len */
import React from 'react';

interface CheckProps {
    className?: string;
    color1?: string;
    height?: number | string;
    testId?: string;
    width?: number | string;
}

export const Check = React.forwardRef<SVGSVGElement, CheckProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M20.53 5.47c.3.3.3.77 0 1.06l-11 11c-.3.3-.77.3-1.06 0l-5-5a.75.75 0 1 1 1.06-1.06L9 15.94 19.47 5.47c.3-.3.77-.3 1.06 0Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Check.displayName = 'Check';
Check.defaultProps = {
    className: '',
    color1: '#0019A5',
    height: 24,
    testId: '',
    width: 24
};