/* eslint-disable max-len */
import React from 'react';

interface UploadProps {
    /**
     * A string representing the CSS class to be applied to the UploadIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the UploadIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the UploadIcon element.
     */
    width?: number | string;
}

/**
 * The `Upload` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `UploadProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the UploadIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the UploadIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the UploadIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const UploadComponent = <Upload className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Upload = React.forwardRef<SVGSVGElement, UploadProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 34 32"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M2.19 19c0-.55.47-1 1.05-1 .54 0 .99.39 1.05.88V25c0 1.05.87 1.92 1.96 2H28.6c1.11 0 2.03-.82 2.1-1.85l.01-.15v-6c0-.55.48-1 1.06-1 .54 0 .99.39 1.05.88V25a4.1 4.1 0 0 1-4 4H6.4a4.13 4.13 0 0 1-4.22-3.8V19m24-7.8a.96.96 0 0 0 .09-1.31l-.1-.1-7.92-7.5a1.1 1.1 0 0 0-1.4-.08l-.1.08-7.92 7.5a.96.96 0 0 0 0 1.42c.39.36.98.39 1.4.08l.1-.08 7.18-6.8 7.17 6.8c.38.36.98.39 1.4.08z" fill={color1} fillRule="evenodd" />
            <path clipRule="evenodd" d="M18.56 3c0-.55-.47-1-1.05-1-.55 0-1 .39-1.05.88l-.01.12v18c0 .55.47 1 1.06 1 .54 0 .98-.39 1.05-.88z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Upload.displayName = 'Upload';
Upload.defaultProps = {
    className: '',
    color1: '#000',
    height: 32,
    testId: 'Upload',
    width: 34
};