import {lighten} from '@nfq/react-grid';

import {useThemeColors} from 'UI/hooks/useThemeColors';

import type {Colors} from 'UI/utils/globalStyles';

/**
 * Returns the foreground color based on the specified style.
 * The foreground color is determined by the theme colors, with 'dark' style using the secondary font color
 * and 'light' style using the primary font color.
 *
 * @param style          The style of the component. Can be either 'dark' or 'light'.
 * @param overwriteColor The explicit color for the return color.
 * @returns The foreground color based on the specified style.
 *
 * @example
 * ```ts
 * const color = useForegroundColor('dark');
 * ```
 */
export const useForegroundColor = (style: 'dark' | 'light', overwriteColor?: Colors) => {
    const colors = useThemeColors();
    let foregroundColor = style === 'dark' ? lighten(colors.primaryFontColor, 100) : colors.primaryActionColor;

    if (overwriteColor) {
        foregroundColor = overwriteColor;
    }

    return foregroundColor as Colors;
};