import type {Variants} from 'framer-motion';

export const OpacityAnimation: Variants = {
    enter: {
        opacity: 1,
        transition: {duration: 0.4}
    },
    exit: {
        opacity: 0,
        transition: {duration: 0.4}
    },
    initial: {opacity: 0}
};

export const SlideDownAnimation: Variants = {
    enter: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        y: -20
    },
    initial: {
        opacity: 0,
        y: -20
    }
};

export const NotificationAnimation: Variants = {
    enter: {
        opacity: 1,
        y: '0%'
    },
    exit: {
        opacity: 0,
        y: '-100%'
    },
    initial: {
        opacity: 0,
        y: '100%'
    }
};

export const FlyoutAnimation: Variants = {
    /**
     * Represents the enter animation state for the flyout.
     * The enter animation sets the opacity to 1 and the y-axis position to '-100%' or '100%' based on the top parameter.
     *
     * @param params          An object containing the isOnTop and position parameters.
     * @param params.isOnTop  A boolean value indicating whether the animation is entering from the top.
     * @param params.position A string value indicating the position of the flyout.
     * @returns An object containing the animation properties for opacity and y-axis position.
     */
    enter({isOnTop, position} = {}) {
        return {
            opacity: 1,
            y: isOnTop ? '-100%' : '100%',
            ...(position === 'center' && {x: '-50%'})
        };
    },
    /**
     * Represents the exit animation state for the flyout.
     * The exit animation sets the opacity to 0 and the y-axis position to '-110%' or '110%' based on the top parameter.
     *
     * @param params          An object containing the isOnTop and position parameters.
     * @param params.isOnTop  A boolean value indicating whether the animation is exiting from the top.
     * @param params.position A string value indicating the position of the flyout.
     * @returns An object containing the animation properties for opacity and y-axis position.
     */
    exit({isOnTop, position} = {}) {
        return {
            opacity: 0,
            y: isOnTop ? '-110%' : '110%',
            ...(position === 'center' && {x: '-50%'})
        };
    },
    /**
     * Represents the initial animation state for the flyout.
     * The initial animation sets the opacity to 0 and the y-axis position to '-110%' or '110%' based on the top parameter.
     *
     * @param params          An object containing the isOnTop and position parameters.
     * @param params.isOnTop  A boolean value indicating whether the animation is starting from the top.
     * @param params.position A string value indicating the position of the flyout.
     * @returns An object containing the animation properties for opacity and y-axis position.
     */
    initial({isOnTop, position} = {}) {
        return {
            opacity: 0,
            y: isOnTop ? '-110%' : '110%',
            ...(position === 'center' && {x: '-50%'})
        };
    }
};

export const PopoverAnimation: Variants = {
    hover: {
        opacity: 1,
        x: '-50%',
        y: '-100%'
    },
    initial: {
        opacity: 0,
        x: '-50%',
        y: '-90%'
    }
};

export const PopoverWrapperAnimation: Variants = {
    hover: {},
    initial: {}
};

export const ZoomInAnimation = {
    zoomIn: {scale: 1},
    zoomOut: {scale: 0}
};