/* eslint-disable no-nested-ternary */
import type {MouseEvent} from 'react';

import styled from 'styled-components';

import {useButtonColors} from './useButtonColors';

import type {WithChildren} from 'types/global';

interface ComponentProps {
    /**
     * The element type to render as.
     * Available options: `'button'` (default) or `'a'`.
     *
     * @default 'button'
     */
    as: 'button' | 'div';
    /**
     * Determines whether the button has a slim border.
     * When set to `true`, the button will be visually appear with a slim border.
     */
    hasSlimBorder: boolean;
    /**
     * Determines whether the button is active.
     * When set to `true`, the button will be visually appear active.
     * This is useful for indicating the current state of the button.
     * For example, when the button is toggled on.
     * This is only applicable to buttons that can be toggled.
     *
     * @default false
     */
    isActive: boolean;
    /**
     * Determines whether the button is disabled.
     * When set to `true`, the button will be non-interactive and visually appear disabled.
     */
    isDisabled: boolean;
    /**
     * Determines whether the button is rounded.
     * When set to `true`, the button will be visually appear rounded.
     */
    isRounded: boolean;
    /**
     * Event handler for the button's click event.
     * This function will be called when the button is clicked.
     *
     * @param event The mouse event object associated with the click event.
     */
    onClick(event: MouseEvent <HTMLButtonElement>): void;
    /**
     * The style variant of the button.
     * This determines the visual style of the button.
     * Available options: `'dark'` (default) or `'light'`.
     *
     * @default 'dark'
     */
    style: 'dark' | 'light';
    /**
     * The test id for the button.
     * This can be used for identifying the button in tests.
     */
    testId: string;
    /**
     * The type of the button.
     * This determines the behavior of the button when clicked.
     * Available options: `'button'` (default), `'reset'`, or `'submit'`.
     *
     * @default 'button'
     */
    type: 'button' | 'reset' | 'submit';
    /**
     * The variant of the button.
     * This determines the visual variant of the button.
     * Available options: `'primary'` (default) or `'secondary'`.
     *
     * @default 'primary'
     */
    variant: 'primary' | 'secondary';
}

/**
 * A base component for creating button elements with customizable styles and behaviors.
 * This component uses the `useButtonColors` hook to determine the color scheme based on the provided props.
 * It supports various styles, states (active/disabled), and can be rendered as different HTML elements.
 *
 * @param props               The component props.
 * @param props.as            The HTML element or React component to use for rendering the button.
 * @param props.children      The content to be displayed inside the button.
 * @param props.hasSlimBorder Boolean indicating if the button should have a slim border.
 * @param props.isActive      Boolean indicating if the button is in an active state.
 * @param props.isDisabled    Boolean indicating if the button is disabled.
 * @param props.isRounded     Boolean indicating if the button should have rounded corners.
 * @param props.onClick       Function to be called when the button is clicked.
 * @param props.style         The style variant ('light' or 'dark') of the button.
 * @param props.testId        A unique identifier for testing purposes.
 * @param props.type          The type of button ('button', 'submit', 'reset', etc.).
 * @param props.variant       The variant ('primary', 'secondary', etc.) of the button.
 * @returns                   A JSX element representing the button.
 *
 * @example
 * ```tsx
 * <ButtonBase
 *   as="button"
 *   variant="primary"
 *   style="light"
 *   onClick={() => console.log('Clicked')}
 *   testId="my-button"
 * >
 *   Click Me
 * </ButtonBase>
 * ```
 */
const ButtonBase = ({
    as,
    children,
    hasSlimBorder,
    isActive,
    isDisabled,
    isRounded,
    onClick,
    style,
    testId,
    type,
    variant
}: WithChildren<ComponentProps>) => {
    const {
        activeBorderColor,
        buttonBaseColor,
        buttonHoverColor,
        buttonPressedColor
    } = useButtonColors(variant, style, hasSlimBorder);

    return (
        <Wrapper
            $activeBorderColor={activeBorderColor}
            $buttonBaseColor={buttonBaseColor}
            $buttonHoverColor={buttonHoverColor}
            $buttonPressedColor={buttonPressedColor}
            $hasSlimBorder={hasSlimBorder}
            $isRounded={isRounded}
            as={as}
            className={isActive ? 'active' : ''}
            data-cy={testId}
            disabled={isDisabled}
            type={type}
            onClick={onClick}
        >
            {children}
        </Wrapper>
    );
};

ButtonBase.displayName = 'ButtonBase';
ButtonBase.defaultProps = {
    as: 'button',
    hasSlimBorder: false,
    isActive: false,
    isDisabled: false,
    isRounded: false,
    /**
     * Default onClick function.
     */
    onClick() {},
    // eslint-disable-next-line @nfq/sort-keys
    style: 'dark',
    testId: undefined,
    type: 'button',
    variant: 'primary'
};

export {ButtonBase};

interface WrapperProps {
    $activeBorderColor: string;
    $buttonBaseColor: string;
    $buttonHoverColor: string;
    $buttonPressedColor: string;
    $hasSlimBorder: boolean;
    $isRounded: boolean;
}

const Wrapper = styled.button<WrapperProps>`
    background-color: ${({$buttonBaseColor}) => $buttonBaseColor};
    border: ${({$hasSlimBorder}) => ($hasSlimBorder ? '1px' : '2px')} solid ${({$buttonBaseColor}) => $buttonBaseColor};
    border-radius: ${({$isRounded}) => ($isRounded ? '50%' : '4px')};
    cursor: pointer;
    outline: none;
    padding: 0;
    transition: background-color 0.3s 0s ease-in-out, border 0.3s 0s ease-in-out, opacity 0.3s 0s ease-in-out;
    will-change: background-color, border, opacity;

    &:hover {
        background-color: ${({$buttonHoverColor}) => $buttonHoverColor};
        border: ${({$hasSlimBorder}) => ($hasSlimBorder ? '1px' : '2px')} solid ${({$buttonHoverColor}) => $buttonHoverColor};
        outline: none;
        transition: border 0.3s 0s ease-in-out, background-color 0.3s 0s ease-in-out;
    }
    &:active, &.active {
        background-color: ${({$buttonPressedColor}) => $buttonPressedColor};
        border: ${({$hasSlimBorder}) => ($hasSlimBorder ? '1px' : '2px')} solid ${({$activeBorderColor}) => $activeBorderColor};
        outline: none;
        transition: border 0.3s 0s ease-in-out, background-color 0.3s 0s ease-in-out;
    }
    &:focus {
        border: ${({$hasSlimBorder}) => ($hasSlimBorder ? '1px' : '2px')} solid ${({theme}) => theme.colors.buttonBorderColor};
        outline: none;
        transition: border 0.3s 0s ease-in-out;
    }
    &:disabled {
        opacity: 0.3;
        outline: none;
        transition: opacity 0.3s 0s ease-in-out;
    }
`;